import { createRouter, createWebHistory } from 'vue-router'
//import DailyMenus from '../views/DailyMenus.vue'
//import Menu from '../views/Menu.vue'
import DailyMenus2 from '../views/DailyMenus2.vue'
import Menu2 from '../views/Menu2.vue'

const routes = [
  {
    path: '/',
    redirect: '/menus'
  },
  //{
  //  path: '/menus',
  //  name: 'DailyMenus',
  //  component: DailyMenus
  //},
  //{
  //  path: '/menus/:id',
  //  name: 'Menu',
  //  component: Menu
  //},
  {
    path: '/menus',
    name: 'DailyMenus2',
    component: DailyMenus2
  },
  {
    path: '/menus/:id',
    name: 'Menu2',
    component: Menu2
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    redirect: '/menus'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
