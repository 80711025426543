<template>
  <!--<div id="nav">
    <router-link to="/menus">Daily Menus</router-link>
  </div>-->
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
  /*max-height: 3500px;*/
  padding-left: 150px;
  display: block;
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
