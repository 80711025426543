export default {
    isTriggerTime: function (hour, minute, from) {
        //console.log("isTriggerTime", hour, minute, from);
        var now = new Date();
        return now.getHours() === hour && now.getMinutes() === minute;
    },

    cancelAutoUpdate: function (timers) {
        if (timers.length > 0) {
            for (i = 0; i < timers.length; i++) {
                clearInterval(timers[i]);
            }
        }
    },

    cancelTimer: function (timer) {
        console.log("Clear Interval");
        clearInterval(timer); //timer.value
    }

}