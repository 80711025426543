<template>
  <nav class="navbar">
    <div class="container" style="max-width: 3700px">
      <a class="navbar-brand" href="#">
        <img src="../assets/logo.png" alt="" height="60" />
      </a>
      <div class="menu_title">Daily Menus</div>
      <div class="menu_title">Mayfair</div>
    </div>
  </nav>

  <!-- <div v-if="loading" class="loading" style="max-width: 3700px">Loading...</div>-->

  <div v-if="error" class="error" style="max-width: 3700px">
    {{ error }}
  </div>

  <div v-if="menus" class="container" style="max-width: 3700px">
    <div class="row menu_list">
      <div
        v-for="menu in menus.menus"
        :key="menu.menu_id"
        class="col-12 list_item"
      >
        <router-link :to="{ name: 'Menu2', params: { id: menu.menu_id } }">
          <h2>{{ menu.menu_name }}</h2>
          {{ menu.start_at }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MenuList from "../components/MenuList.vue";
import { ref, onUnmounted } from "vue";
import router from "../router";
import timerUtils from "../composables/timerUtils";

//import getMenus from "../composables/getMenus";

export default {
  components: { MenuList },
  setup() {
    //const { menus, error, load } = getMenus;
    //load();
    const menus = ref(null);
    const error = ref(null);
    const timer = ref(null);

    const load = async () => {
      //console.log("LOAD ....");
      try {
        let data = await fetch(process.env.VUE_APP_API_URL);
        if (!data.ok) {
          throw Error("No data available");
        }

        menus.value = await data.json();

        var start_at = menus.value.menus[0].start_at;
        var time = start_at.split(":");
        var hour = Number($.trim(time[0]));
        var min = Number($.trim(time[1]));

        // console.log("START TIME = ", hour, min);

        timer.value = setInterval(function () {
          if (timerUtils.isTriggerTime(hour, min, "daily_menus")) {
            //console.log(menus.value.menus);

            router.push({
              name: "Menu2",
              params: {
                id: menus.value.menus[0].menu_id,
                all_menus: JSON.stringify(menus.value.menus),
              },
            });
          }
        }, 400);
      } catch (err) {
        error.value = err.message;
        //console.log(error.value);
      }
    };

    onUnmounted(() => {
      clearInterval(timer.value);
      //timerUtils.cancelTimer(timer);
    });

    //function cancelAutoUpdate() {
    //  console.log("Clear Interval");
    //  clearInterval(timer.value);
    //}

    load();

    return { menus, error };
  },
};
</script>

<style>
.navbar {
  padding: 40px;
  color: white;
  background-color: black;
}

.navbar .container {
  padding-top: 60px;
}

.menu_list {
  padding-top: 60px;
}

/* unvisited link */
a:link,
a:visited,
a:active {
  color: white;
}

/* mouse over link */
a:hover {
  color: lightskyblue;
}
</style>