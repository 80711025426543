<template>
  <div v-for="menu in menus.menus" :key="menu.menu_id" class="menu">
    <router-link :to="{ name: 'Menu', params: { id: menu.menu_id } }">
      <h2>{{ menu.menu_name }} {{ menu.menu_id }}</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["menus"],
  setup(props) {
    console.log(props.menus);
  },
};
</script>

<style>
</style>