<template>
  <nav class="navbar">
    <div class="container" style="max-width: 3700px">
      <a href="#">
        <img src="../assets/logo.png" alt="" height="60" />
      </a>
      <div v-if="menu" class="menu_title">
        {{ menu.menu_name }}
      </div>
      <div class="menu_title">Mayfair</div>
    </div>
  </nav>

  <div v-if="loading" class="loading" style="max-width: 3700px">Loading...</div>

  <div v-if="error" class="error" style="max-width: 3700px">
    {{ error }}
  </div>

  <div v-if="menu" class="container" style="max-width: 3700px">
    <div class="row">
      <template
        v-for="(cat_section, cat_section_idx) in menu.category_sections"
        :key="cat_section.id"
      >
        <div :id="'cat_section_' + cat_section.id" class="section col-3">
          <template
            v-for="section in cat_section.sections"
            :key="section.section_id"
          >
            <div :class="section.show == true ? 'collapse show' : 'collapse'">
              <div
                class="section_header underline"
                :class="'section_' + cat_section_idx"
              >
                <template v-if="section.section_name">
                  {{ section.section_name }}
                </template>
                <template v-else>
                  {{ section.category_name }}
                </template>
              </div>
              <div
                :id="section.section_id + 'carousel'"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
                style="featured_carousel"
              >
                <div class="featured carousel-inner">
                  <div
                    v-for="(featured, index) in section.featured"
                    :key="featured.product_id"
                    class="carousel-item"
                    :class="{ active: index == 0 }"
                  >
                    <div class="card" style="background-color: black">
                      <div class="card-header">
                        {{ featured.product_name }}
                      </div>
                      <div style="align-items: center">
                        <div>
                          <img
                            :src="featured.image"
                            class="card-img-top"
                            alt="#"
                            style="width: 33%; height: 66%"
                          />
                        </div>
                      </div>
                      <!--<div class="card-footer">
                    <div v-if="featured.has_variants" class="row">
                      <div
                        v-for="variant in featured.variants"
                        :key="variant.variant_id"
                        :class="'col-' + 12 / featured.variants.length"
                      >
                        <small class="text-muted">{{
                          variant.variant_short_name
                        }}</small
                        >{{ variant.price }}
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-12">{{ featured.price }}</div>
                    </div>
                  </div>-->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="sub_section in section.sub_sections"
                :key="sub_section.sub_section_id"
                class="sub_section"
              >
                <!-- <div class="sub_section_header">-->
                <!-- <h2>{{ sub_section.sub_section_name }}</h2>-->
                <!--   {{ sub_section.sub_category_name }}
            </div>-->

                <div class="row">
                  <template
                    v-for="(option, index) in sub_section.price_options"
                    :key="index"
                  >
                    <!--<div
                v-for="(option, index) in sub_section.price_options"
                :key="option + index"
                :class="'col-' + 12 / sub_section.price_options.length"
              >-->
                    <div
                      v-if="index == 0"
                      class="col-6"
                      :class="'subcat_' + cat_section_idx"
                    >
                      <template v-if="sub_section.sub_section_name">
                        {{ sub_section.sub_section_name }}
                      </template>
                      <template v-else>
                        {{ sub_section.sub_category_name }}
                      </template>

                      <!-- {{ sub_section.sub_category_name }}-->
                    </div>
                    <template v-if="sub_section.price_options.length == 2">
                      <div :class="'col-1 price_option'">
                        <p class="text-center">{{ option }}</p>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="price_option"
                        :class="'col-' + 3 / sub_section.price_options.length"
                      >
                        <p class="text-center">{{ option }}</p>
                      </div>
                    </template>

                    <!-- <div :class="'col-' + 12 / sub_section.price_options.length">
                <p class="text-center">{{ option }}</p>
              </div>-->
                  </template>
                </div>
                <div
                  v-for="product in sub_section.products"
                  :key="product.product_id"
                  class="row product"
                >
                  <div v-if="product.sub_section_product_name" :class="'col-6'">
                    <p class="text-left">
                      {{ product.sub_section_product_name }}
                    </p>
                  </div>
                  <div v-else :class="'col-6'">
                    <p class="text-left">{{ product.product_name }}</p>
                  </div>

                  <template v-if="product.has_variants">
                    <template v-if="sub_section.price_options.includes('P')">
                      <template v-if="product.variants.length == 1">
                        <template
                          v-for="(opt, index) in sub_section.price_options"
                          :key="index"
                        >
                          <div
                            v-if="index == 0"
                            :class="
                              'col-' + 3 / sub_section.price_options.length
                            "
                          >
                            <p class="text-center">
                              {{ product.variants[0].price }}
                            </p>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-for="(opt, index) in sub_section.price_options"
                          :key="index"
                        >
                          <template v-if="index == 0">
                            <div
                              :class="
                                'col-' + 3 / sub_section.price_options.length
                              "
                            ></div>
                          </template>
                          <template v-else>
                            <template
                              v-if="sub_section.price_options.length == 2"
                            >
                              <div :class="'col-1'">
                                <p class="text-center">
                                  {{ product.variants[index - 1].price }}
                                </p>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                :class="
                                  'col-' + 3 / sub_section.price_options.length
                                "
                              >
                                <p class="text-center">
                                  {{ product.variants[index - 1].price }}
                                </p>
                              </div>
                            </template>

                            <!--<div
                          :class="'col-' + 3 / sub_section.price_options.length"
                        >
                          <p class="text-center">
                            {{ product.variants[index - 1].price }}
                          </p>
                        </div>-->
                            <!-- <div
                        v-for="(variant, idx) in product.variants"
                        :key="variant.variant_id"
                        :class="'col-' + 6 / sub_section.price_options.length"
                      >
                        <p class="text-center">
                          {{ index }} {{ idx }}{{ variant.price }}
                        </p>
                      </div>-->
                          </template>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-for="(opt, index) in sub_section.price_options"
                        :key="index"
                      >
                        <template v-if="sub_section.price_options.length == 2">
                          <div :class="'col-1'">
                            <p class="text-center">
                              {{ product.variants[index].price }}
                            </p>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            :class="
                              'col-' + 3 / sub_section.price_options.length
                            "
                          >
                            <p class="text-center">
                              {{ product.variants[index].price }}
                            </p>
                          </div>
                        </template>

                        <!--<div :class="'col-' + 3 / sub_section.price_options.length">
                      <p class="text-center">
                        {{ product.variants[index].price }}
                      </p>
                    </div>-->
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div class="col-3">
                      <p class="text-center">
                        {{ product.price }}
                      </p>
                    </div>
                  </template>

                  <!-- <div class="col-sm-6">
              <p class="text-left">{{ product.product_name }}</p>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div
                  v-for="variant in product.variants"
                  :key="variant.variant_id"
                  class="col-3"
                >
                  <small class="text-muted">{{
                    variant.variant_short_name
                  }}</small>
                  <p class="text-right">{{ variant.price }}</p>
                </div>
              </div>
            </div>-->
                </div>
              </div>
            </div>

            <!-- <div v-if="index == 0 || index == 1" class="col-1">-->
            <!--<div class="borderLeft"></div>-->
            <!--</div>-->
          </template>
        </div>
      </template>
    </div>
    <!--<div class="text-center footer">
      {{ menu.price_key }}
    </div>-->
    <!-- <div
      class="text-center footer"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      {{ menu.price_key }}. For our complete menu visit menu.arclesalon.com or
      speak to our staff. © 2021 ARC LE SALON
    </div>-->
  </div>

  <!--</div>-->

  <div>
    <div
      class="text-center footer"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      <template v-if="menu">{{ menu.price_key }}.</template> For our full menu
      visit menu.arclesalon.com or talk to our staff. © 2021 ARC LE SALON
    </div>
  </div>
</template>

<script>
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
//import * as bootstrap from "bootstrap";
import router from "../router";
import timerUtils from "../composables/timerUtils";

export default {
  data() {
    return {
      loading: false,
      menu: null,
      post: null,
      error: null,
      timer: null,
      other_menus: [],
      timers: [],
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    //console.log("CREATED");
    if (this.timer != null) {
      //timerUtils.cancelTimer(this.timer);
      clearInterval(this.timer);
    }

    this.fetchData();
  },

  onUnmounted() {
    //console.log("UNMOUNTED ... cancel timer");
    //Clear the Interval when the view is unmounted
    //timerUtils.cancelTimer(this.timer);
    clearInterval(this.timer);
  },

  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      //if (this.timer != null) {
      //  console.log("timer is not null");
      //  console.log(this.timer);
      //  timerUtils.cancelTimer(this.timer);
      // }
      this.error = null;
      this.post = null;
      this.loading = true;
      const currentMenuId = this.$route.params.id;
      //const api_url = process.env.VUE_APP_API_URL + "test/";
      const api_url = process.env.VUE_APP_API_URL;
      this.timer = null;

      if (this.$route.params.id !== currentMenuId) return;

      console.log("all menus = ", this.$route.params.all_menus);
      if (this.$route.params.all_menus) {
        const all_menus = JSON.parse(this.$route.params.all_menus);

        // Filter out the remaining menus for the day i.e. all menus except the currently displaying menu
        var other_menus = all_menus.filter(function (obj) {
          return obj["menu_id"] !== parseInt(currentMenuId);
        });

        //console.log(other_menus);
        //console.log("other_menus length", other_menus.length);

        // If other_menus = 0 this means this that the last menu for the day is displaying,
        // So at 5am (Trigger time: 5, 0) re-direct to the daily menus
        console.log("other menus lgth", other_menus.length);
        if (other_menus.length == 0) {
          //console.log("----------- set Interval 1 ........... 22:54");
          this.timer = setInterval(function () {
            if (timerUtils.isTriggerTime(5, 0, "is_menu_lgth_0")) {
              //console.log("----------- clear the timer 1 ...........");
              clearInterval(this.timer);
              // Change back to 5, 0
              router.push({
                name: "DailyMenus2",
              });
            }
          }, 400);
        } else {
          // Set the timer for when the next menu should display
          // When time is reached, redirect to the next menu id
          // Next menu, is the first item (index = 0) in the array, as the other_menus are in ascending time order
          var next_menu_id = other_menus[0]["menu_id"];
          var next_menu_start = other_menus[0]["start_at"];
          var time = next_menu_start.split(":");
          var hour = Number($.trim(time[0]));
          var min = Number($.trim(time[1]));

          //console.log(next_menu_id);
          // console.log(next_menu_start);
          //console.log("NEXT MENU START @ = ", hour, min);
          //console.log("OTHER MENUS ", JSON.stringify(other_menus));

          //console.log("----------- ELSE: set Interval 2 ...........");
          //console.log("other_menus length", other_menus.length);

          //set the time interval
          this.timer = setInterval(function () {
            // When the time is met, re-direct
            if (timerUtils.isTriggerTime(hour, min, "is_menu_lgth")) {
              //console.log("----------- clear the timer 2 ...........");
              clearInterval(this.timer);
              router.push({
                name: "Menu2",
                params: {
                  id: parseInt(next_menu_id),
                  all_menus: JSON.stringify(other_menus),
                },
              });
            }
          }, 400);
        }
      } else {
        this.timer = setInterval(function () {
          if (timerUtils.isTriggerTime(5, 0, "is_menu_lgth_0")) {
            //console.log("----------- clear the timer 1 ...........");
            clearInterval(this.timer);
            // Change back to 5, 0
            router.push({
              name: "DailyMenus2",
            });
          }
        }, 400);
      }

      if (currentMenuId) {
        // Get the data
        fetch(api_url + currentMenuId, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            access_token: process.env.VUE_APP_API_KEY,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            this.loading = false;
            this.menu = data;

            // set the response data
            var menus = this.menu;

            if (menus.category_sections != null) {
              $(function () {
                let _this = this;

                //console.log(menus.sections);
                for (var i = 0; i < menus.category_sections.length; i++) {
                  var cat_section = menus.category_sections[i];
                  var container_elems =
                    "div#cat_section_" + cat_section["id"] + " > div";

                  if (
                    cat_section["changes"] == true &&
                    cat_section["change_frequency"] > 0 &&
                    cat_section["sections"].length > 1
                  ) {
                    var changes_milliseconds =
                      cat_section["changes"] * 60 * 1000;

                    // 1 minutes
                    (function showpanel(i, elems) {
                      elems
                        .eq(i)
                        .fadeIn()
                        .delay(changes_milliseconds)
                        .hide(0, function () {
                          showpanel(++i % elems.length, elems);
                        });
                    })(0, $(container_elems));
                  }

                  for (var j = 0; j < cat_section.sections.length; j++) {
                    var section_carousel_id =
                      "#" + cat_section.sections[j].section_id + "carousel";

                    if ($(section_carousel_id).length) {
                      $(section_carousel_id).carousel({
                        interval: 12000,
                        cycle: true,
                      });
                    }
                  }
                }
              });
            }
          })
          .catch((err) => {
            this.error = err;
            // In case a custom JSON error response was provided
            if (err.json) {
              return err.json.then((json) => {
                // set the JSON response message
                this.error = json.message;
              });
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
.container {
  max-width: 3700px;
}
.navbar {
  padding: 40px;
  color: white;
  background-color: black;
}

.navbar .container {
  padding-top: 60px;
}

.menu_title {
  font-size: 60px;
  text-align: center;
}

.underline {
  background-image: linear-gradient(white, white);
  background-position: bottom center; /*Adjust the background-position to move the line*/
  background-size: 30% 10px; /*Adjust the background size to control length and height*/
  background-repeat: no-repeat;
  padding-bottom: 4px; /* this can also control the position */
}

.price_option {
  font-size: 35px;
  text-align: center;
}

.subcat_option {
  font-size: 40px;
  text-align: left;
  color: lightskyblue;
}

.section_0,
.section_1,
.section_2,
.section_3 {
  font-size: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.subcat_0,
.subcat_1,
.subcat_2,
.subcat_3 {
  font-size: 40px;
  text-align: left;
}

.section_0,
.subcat_0 {
  color: #734f96;
}

.section_1,
.subcat_1 {
  color: #c59e1f;
}

.section_2,
.subcat_2 {
  color: #bb4430;
}

.section_3,
.subcat_3 {
  color: #7ebdc2;
}

.section_header {
  font-size: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.section {
  padding-bottom: 20px;
}

.section_header {
  font-size: 30px;
}

.sub_section_header {
  font-size: 40px;
  padding: 40px;
}

.sub_section {
  padding-bottom: 30px;
}

.featured_card {
  background-color: black;
  color: white;
  font-size: 40px;
}

.product {
  text-align: left;
  font-size: 35px;
  padding-left: 20px;
}

.card-header {
  font-size: 30px;
}

.card-footer {
  font-size: 30px;
}

.footer {
  font-size: 35px;
}
</style>